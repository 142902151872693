<template>
    <div class="box-warper" id="warper" >
        <div class="box-list" ref="scrollRef">
            <!-- <van-checkbox class="box-left" v-model="item.checkbox" :name="item.id" @change="checkboxChange(item,index)"></van-checkbox> -->
            <van-radio-group v-model="radioData"  >
                <div v-for="(item,index) in obj" :key="index" class="listbox">
                    <van-radio :name="item">
                        <div class="box-right">
                            <van-field :value="item.carNumberPlate" label="车牌号" />
                            <van-field :value="item.vin" label="车架号" />
                            <van-field :value="item.defined_num" label="自定义编号" />
                            <van-field :value="item.engineNumber" label="发动机号" />
                        </div>
                    </van-radio>
                </div>
            </van-radio-group>
                
        </div>
        <div class="box-btn">
            <van-button @click="$emit('popupCloes')">取消</van-button>
            <van-button type="primary" @click="isOk">确定</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        props:['Getdata'],
        data() {
            return {
                radioData:{},obj:{}
            }
        },
       watch: {
            Getdata: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    this.obj =newValue
                    this.$refs.scrollRef.scrollTop= 0;
                }
            }
       },
        methods: {
           
            isOk(){
                this.$emit('popupCloes',this.radioData)
            },
        },
    }
</script>

<style lang="scss" scoped>
.box-warper{
    padding: 10px;
    .box-list{     
        height: 80vh;
        overflow-y: scroll;
    }
    .box-btn{
     
        text-align: center;
        border-top: 2px solid #eee;
        .van-button{
            margin-top: 30px;
        }
    }
}
.listbox{
    margin-bottom: 10px;
    border-bottom: 1px solid #000;

}
</style>